import {Amplify, Storage} from 'aws-amplify';
import aws_config from '../../modified_aws-export';
import {useEffect, useState} from 'react';
import {ResultMetadata} from "./IResultMetadata";
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import {copyFilenameToClipboard} from '../../utils/manualUtils';

Amplify.configure(aws_config);

interface OnlyMatchToOEMResultProps {
    elements: any;
    metadata: ResultMetadata;
    onUnmatchOEMClick: (metadata: any) => void;
}

const OnlyMatchToOEMResults: React.FC<OnlyMatchToOEMResultProps> = ({metadata, elements, onUnmatchOEMClick}) => {
    const [img, setImg] = useState<string | undefined>(undefined)
    const [imgTA, setImgTA] = useState<string | undefined>(undefined)
    const {t} = useTranslation();

    const fetchImages = async () => {
        const config: any = {
            level: 'public',
            customPrefix: {public: ''}
        };

        const omeImg = await Storage.get(`processed_images/${metadata.FileName}.png`, {
            ...config
        });
        setImg(omeImg);
        if (metadata.FileName.includes("OEM")) {
            let taImg = await Storage.get(`processed_images/${metadata.FileName.replace('OEM', 'TA')}.png`, {
                ...config
            });
            setImgTA(taImg)
        }
    }

    useEffect(() => {
        Storage.configure({
            aws_user_files_s3_bucket_region: aws_config.backend.region,
            aws_user_files_s3_bucket: aws_config.backend.bucket_name
        })
        fetchImages();
    }, [])

    return (
        <div className="card card-body m-2 p-2" style={{maxWidth: "12em", width: "12em"}}>
            <img src={img} className="card-img-top" alt={metadata.FileName}/>
            {imgTA ?
                <img src={imgTA} className="card-img-top" alt={metadata.FileName.replace('OEM', 'TA')}/>
                : null
            }
            <div className="card-body">
                <p className="card-text">{t("brand")}: {metadata.Manufacturer}</p>
                <p className="card-text"
                   onDoubleClick={() => copyFilenameToClipboard(metadata.FileName, t("CopyToClipboardMessage"))}>{metadata.FileName.replace('-OEM', '')}</p>
                <button style={{marginTop: "-10px"}} className="btn btn-outline-secondary btn-sm" type="button"
                        onClick={() => onUnmatchOEMClick(metadata)}>
                    {t("Button.unmatch")}
                </button>
            </div>
            <div className="d-flex justify-content-center">
                {elements ?
                    <Link to={metadata.Manufacturer}>
                        <button
                            className="btn btn-secondary bs-secondary">{t('Button.showProductCategorieResults').replace('{}', elements.length.toString())}</button>
                    </Link>
                    : null
                }
            </div>
        </div>
    )
}

export default OnlyMatchToOEMResults