
import React, { useEffect, useState } from 'react'
import './DropZone.scss';
import { useTranslation } from "react-i18next";
import ImageCanvas from './ImageCanvas';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { DropFile } from './DropZone'
import { getDropZoneResults } from '../../api/backend';
import { useFilterState, useResultsState } from '../../store';
import { useRecordEvent } from 'aws-rum-react';
import {setLocalItem} from '../../store/slices/pdfuploadSlice'


interface DropZoneResultProps {
}


export const loader = async () => {
    return await getDropZoneResults();

}

const DropZoneResult: React.FC<DropZoneResultProps> = () => {
    const location = useLocation();
    const state: DropFile[] = location.state
    const recordEvent = useRecordEvent();

    const { t } = useTranslation();
    const navigate = useNavigate();

    let dropresults = useLoaderData() as DropFile[]
    const [results, setresults] = useState<DropFile[]>([])
    const { emptyResults, getResultLength  } = useResultsState()
    const { emptyFilter } = useFilterState()

    useEffect(() => {
        emptyFilter();
        setLocalItem('openInNewTab', 'false');
        if (!dropresults || dropresults.length === 0) {
            if (state) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                setresults(state)
            } else {
                navigate('/')
            }
        } else {
            setresults(dropresults)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openInNewTabs = () => {
        let isNotBlocked = true;
        results.map((elem: any, index) => {
            if (isNotBlocked) {
                let newWin = window.open(`${window.location}/${elem.id}?openInNewTab=true`, '_blank')
                if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
                    alert(t("DropZone.hintMultipleTabs"))
                    isNotBlocked = false
                }
            }

        })
        recordEvent('OpenAllResultsInNewTabs', { "user_interaction": "click", "resultCount": results.length });
    }

    const handleBackClick = () => {
        // setLocalItem('openInNewTab', 'false');
        emptyResults(); 
        emptyFilter(); 
        navigate('/');
      };


    return (

        <div className='card border-0'>
            {results.length === getResultLength() ?
                <button className="btn btn-secondary  mt-2" type="button" onClick={() => { openInNewTabs(); }}>
                    {t("Button.openAllResultsInNewTabs")}
                </button>
                :
                <button className="btn btn-secondary  mt-2" disabled type="button" onClick={() => { openInNewTabs(); }}>
                    {t("Button.openAllResultsInNewTabs")}
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>}


            <div className='text-center'>
                {
                    results.map((elem: any, index) => {
                        return <ImageCanvas dropfile={elem} key={index.toString()} />
                    })
                }
            </div>
            <button className="btn btn-secondary  mt-2" type="button" onClick={handleBackClick}>
                {t("Button.back")}
            </button>
        </div>
    )
}

export default DropZoneResult