/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const calculationFinished = /* GraphQL */ `mutation CalculationFinished($input: FileInput!) {
  calculationFinished(input: $input) {
    id
    results
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CalculationFinishedMutationVariables,
  APITypes.CalculationFinishedMutation
>;
export const specialToolsExtractionFinished = /* GraphQL */ `mutation SpecialToolsExtractionFinished($input: FileInput!) {
  specialToolsExtractionFinished(input: $input) {
    id
    results
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SpecialToolsExtractionFinishedMutationVariables,
  APITypes.SpecialToolsExtractionFinishedMutation
>;
export const submitImageFeedback = /* GraphQL */ `mutation SubmitImageFeedback(
  $id: String!
  $bucket: String!
  $key: String!
  $isUseful: Boolean!
) {
  submitImageFeedback(id: $id, bucket: $bucket, key: $key, isUseful: $isUseful)
}
` as GeneratedMutation<
  APITypes.SubmitImageFeedbackMutationVariables,
  APITypes.SubmitImageFeedbackMutation
>;
