// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_ssm_param_rmi_company_name": "/special-tools/feature/rmi-service-company",
    "aws_ssm_param_rmi_secret_name": "/special-tools/feature/rmi-service-password",
    "aws_ssm_param_rmi_user_name": "/special-tools/feature/rmi-service-username",
    "aws_ssm_param_rmi_type_id": "/special-tools/feature/rmi-service-type-id",
    "RumUserMonitoring": {
        "RumMonitorId": "722085ff-3544-4c2d-abb9-5b1aba377fca",
        "RumRegion": "eu-central-1",
        "RumTelemetries": "errors,http"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:33e29752-50a7-4ef1-a363-4f7f3e8c664d",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://feature-image-extraction.d1dalb5kmxgzdl.amplifyapp.com/,https://feature-image-extraction.rmi.sandbox.imagesimilarity.tecalliance.net/,https://rmi.sandbox.imagesimilarity.tecalliance.net/",
        "domain": "tecrmi-auth-feature-image-extraction.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://feature-image-extraction.d1dalb5kmxgzdl.amplifyapp.com/,https://feature-image-extraction.rmi.sandbox.imagesimilarity.tecalliance.net/,https://rmi.sandbox.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_D0aUTRjaB",
    "aws_user_pools_web_client_id": "6b4s777qir09d86uj3eejqq0ct",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "TecRMIfeatureimaoAzureAD9A62CA83",
    "aws_cognito_social_providers": [],
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://z2ledx6fxfgrzdxvn3dktbhtxa.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_user_files_s3_bucket": "tecrmi-feature-image-extraction-frontend",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "backend": {
        "region": "eu-north-1",
        "bucket_name": "tecrmi-manuals-feature"
    },
    "amplify_appid": "d1dalb5kmxgzdl",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
