import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import {ErrorPage} from './components/Errorpage';
import {PdfFileUpload} from './components/PdfFileUpload';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import ResultPage, {loader as resultLoader} from './components/Result/ResultPage';
import {Config, DropZone} from './components';
import './index.css';
import DropZoneResult, {loader as dropZoneResultLoader} from './components/DropZone/DropZoneResult';
import ManufacturerResultPage, {loader as ManufacturerResultLoader} from './components/Result/ManufacturerResult';
import {AwsRumProvider} from 'aws-rum-react';
import aws_config from './modified_aws-export';
import OnlyMatchToOEMPage from "./components/Result/OnlyMatchToOEMPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/image-matcher" replace />
      },
      {
        path: "image-matcher",
        element: <DropZone />,
        children: [
          {
            path: "results",
            element: <DropZoneResult />,
            loader: dropZoneResultLoader,
          },
          {
            path: "results/:uuid",
            element: <ResultPage />,
            loader: resultLoader,
          },
          {
            path: "results/:uuid/:manufacturer",
            element: <ManufacturerResultPage />,
            loader: ManufacturerResultLoader,
          },
          {
            path: "config",
            element: <Config />,
          }
          
        ],
      },
      {
        path: "special-tools-finder",
        element: <PdfFileUpload />,
      },
      {
        path: "only-match-to-oem",
        element: <OnlyMatchToOEMPage/>,
      },
    ]
  },

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AwsRumProvider
      allowCookies
      endpoint="https://dataplane.rum.eu-central-1.amazonaws.com"
      id={aws_config.RumUserMonitoring.RumMonitorId}
      identityPoolId={aws_config.aws_cognito_identity_pool_id}
      region="eu-central-1"
      sessionSampleRate={1}
      telemetries={aws_config.RumUserMonitoring.RumTelemetries.split(",")}
      version="1.0.0"
      sessionAttributes={{ "screenResolution": window.screen.availWidth + " x " + window.screen.availHeight }}
    >
      <RouterProvider router={router} />
    </AwsRumProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
