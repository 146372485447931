import {Storage} from "aws-amplify";
import aws_config from "../../../modified_aws-export";

export interface IOptionType {
    value: string
    label: string
}

export const fetchManufacturers = async () => {
    let desiredListManufacturers = [];
    let manufacturerListUrl = await Storage.get(`REFERENCEDATA/manufacturers.json`, {
        bucket: aws_config.backend.bucket_name,
        region: aws_config.backend.region,
        level: 'public',
        customPrefix: {public: ''},
        validateObjectExistence: true
    });
    const responseManufacturers = await fetch(manufacturerListUrl).then((res => res.json()))

    for (let key of Object.keys(responseManufacturers)) {
        let desired = {
            value: key,
            label: responseManufacturers[key] + " (" + key + ")"
        };
        desiredListManufacturers.push(desired);
    }
    return desiredListManufacturers;
}