import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Button, Card, Container, Form, ListGroup, OverlayTrigger, Row, Spinner} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {handleSpecialToolMatch} from '../../api/backend';
import {Amplify, Storage} from 'aws-amplify'
import './PdfFileUpload.scss';
import CreatableSelect from 'react-select/creatable';
import {useTranslation} from "react-i18next";
import {ImageManual, ImageManualItem, Manual, ManualItem} from '../../utils/manualUtils'
import Tooltip from 'react-bootstrap/Tooltip';
import {toast} from 'react-toastify';
import {useRecordEvent} from 'aws-rum-react';
import {useExtractedImagesState, useI18nState, usePdfUploadState} from '../../store';
import aws_config from '../../modified_aws-export';
import ManualFinder from '../ManualFinder/ManualFinder';
import {ExtractedItem} from "../shared/models/constants";
import {MultiValue} from "react-select";
import {ISpecialToolMatchPayload} from "../shared/models/specialTools";
import {fetchManufacturers, IOptionType} from "../shared/models/common";

Amplify.configure(aws_config);


const PdfFileUpload: React.FC = () => {
    const {t} = useTranslation();
    const {selectedLanguage} = useI18nState();
    const {
        uploadComplete,
        setUploadComplete,
        uploadedFiles,
        setUploadedFiles,
        fileMap,
        setFileMap,
        clearUploadedFiles,
        clearFileMap,
        setExtractionFinished,
        extractedImages,
        setExtractedImages,
        specialTools,
        setSpecialTools,
        setManualsBasedOnSpecialTools,
        setManualsBasedOnImages,
    } = usePdfUploadState()
    const [progress, setProgress] = useState(0);
    const [files, setFiles] = useState<File[]>([]);
    const [threshold, setThreshold] = useState<number>(0.5); // State for input field
    const [topK, setTopK] = useState<number>(5); // State for input field
    const [itemMPArray, setItemMPArray] = useState<string[]>([]);
    const [manufacturers, setManufactures] = useState<IOptionType[]>([]);
    const [selectedManufacturers, setSelectedManufacturers] = useState<IOptionType[]>([]);
    const [itemMP, setItemMP] = useState<string>('');
    const [uploading, setUploading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false); // State for submit spinner
    const [options, setOptions] = useState<IOptionType[]>([]);
    const [extractOptions, setExtractOptions] = useState<IOptionType[]>([]);
    const [selectedItem, setSelectedItem] = useState<IOptionType | null>(null);
    const [selectedExtractItem, setSelectedExtractItem] = useState<IOptionType | null>(null);
    const {resetSelectedImages} = useExtractedImagesState()
    const [extractType, setExtractType] = useState(ExtractedItem.SpecialTools);
    const recordEvent = useRecordEvent();
    Storage.configure({
        aws_user_files_s3_bucket_region: aws_config.aws_user_files_s3_bucket_region,
        aws_user_files_s3_bucket: aws_config.aws_user_files_s3_bucket
    })

    useEffect(() => {
        setExtractOptions([
            {value: ExtractedItem.SpecialTools, label: t("Sidebar.SpecialTools")},
            {value: ExtractedItem.ImagesManual, label: t("Sidebar.ImagesManualSelection")},
            {value: ExtractedItem.ImagesAutomatic, label: t("Sidebar.ImagesAutomaticSelection")}
        ]);
        setSelectedExtractItem({value: ExtractedItem.SpecialTools, label: t("Sidebar.SpecialTools")});
        fetchManufacturers().then(desiredListManufacturers => {
            setManufactures(desiredListManufacturers);
        })
    }, [selectedLanguage])

    useEffect(() => {
        setExtractionFinished(false)
        resetSelectedImages()
        const fetchFile = async () => {
            try {
                // Fetch the file from AWS S3
                const result = await Storage.get('REFERENCEDATA/item_mp_names.json', {
                    level: 'public',
                    customPrefix: {public: ''},
                    download: true
                });
                // Check if the file is returned as a Blob
                if (result && typeof result === 'object' && 'Body' in result) {
                    const {Body} = result as { Body?: Blob };

                    if (Body instanceof Blob) {
                        // Read the Blob as text
                        const text = await Body.text()

                        // Parse the JSON content
                        const data = JSON.parse(text);
                        setItemMPArray(data)
                        const initialOptions: IOptionType[] = data.map((item: string) => ({
                            value: item,
                            label: item
                        }));

                        setOptions(initialOptions)

                    } else {
                        console.error('No file content found or incorrect file type.');
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchFile();
    }, []);


    const MAX_RETRIES = 3; // Maximum number of retry attempts
    const RETRY_DELAY_MS = 5000; // Delay between retries in milliseconds

    const uploadFile = async (file: File, index: number, fileProgress: number[], totalFiles: number, retries = MAX_RETRIES): Promise<string> => {
        const key = `special_tools_extraction/pdf_files/${file.name}`;
        try {
            const result = await Storage.put(key, file, {
                contentType: file.type,
                customPrefix: {public: ""},
                progressCallback(progress: any) {
                    if (progress.total > 0) {
                        fileProgress[index] = progress.loaded / progress.total;
                    } else {
                        fileProgress[index] = 0; // Handle case where progress.total is 0
                    }
                    const totalProgress = fileProgress.reduce((acc, curr) => acc + curr, 0) / totalFiles;
                    setProgress(totalProgress * 100);
                }
            });

            const path = result.key;
            let {eTag} = await Storage.getProperties(path, {
                customPrefix: {public: ""}
            });

            const fileName = key.split("/")[2];
            eTag = eTag.slice(1, -1);
            setFileMap({fileName, eTag});
            setUploadedFiles(file);

            return result.key;
        } catch (error) {
            if (retries > 0) {
                console.warn(`Upload failed. Retrying... (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})`);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS)); // Wait before retrying
                return uploadFile(file, index, fileProgress, totalFiles, retries - 1); // Retry with reduced attempts
            } else {
                console.error('Upload failed after maximum retries:', error);
                recordEvent('PDFUploadError', {'error': error});

                throw error;
            }
        }
    };

    const onUpload = useCallback(async (files: File[]) => {
        recordEvent('StartUploadingPdfEvent', {'description': 'Extraction of special tools for uploaded PDF files was started'});
        try {
            setUploading(true);
            const fileProgress = new Array(files.length).fill(0);
            const uploadPromises = files.map((file, index) => uploadFile(file, index, fileProgress, files.length));
            await Promise.all(uploadPromises);
            setProgress(100);
            setUploadComplete(true);
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error uploading files:', error.message); // Log the error message
                toast.error(`Error uploading files: ${error.message}`, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                });
            } else {
                console.error('Unknown error occurred:', error); // Log unexpected errors
                toast.error(`Unknown error occurred: ${error}`, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                });
            }
        } finally {
            setUploading(false);
        }
    }, []);


    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        // await onUpload(acceptedFiles);
    }, [onUpload]);

    const onClickUpload = async () => {
        await onUpload(files);
    }

    const clearFiles = () => {
        setProgress(0);
        setUploading(false);
        setUploadComplete(false)
        setItemMP('')
        setSelectedItem(null);
        setFileMap({})
        clearUploadedFiles()
        setLoadingSubmit(false)
        clearFileMap()
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
        },
        multiple: true,
        onDrop,
        // disabled: uploading || uploadComplete,
    });

    const dropzoneStyle: React.CSSProperties = {
        padding: '20px',
        border: '2px dashed #ccc',
        textAlign: 'center',
        opacity: uploading || uploadComplete ? 0.5 : 1,
        pointerEvents: uploading || uploadComplete ? 'none' : 'auto',
        height: '250px'

    };

    const removeButtonStyle: React.CSSProperties = {
        position: 'absolute',
        top: '0px',
        right: '0px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Ensure only numbers are accepted
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setTopK(parseInt(value, 10));
        }
    };

    const handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Ensure only numbers are accepted
        const value = event.target.value;
        setThreshold(parseFloat(value));
    };

    const handleSelectChangeExtractType = (selectedValue: IOptionType) => {
        setSelectedExtractItem(selectedValue);
        if (selectedValue) {
            setExtractType(selectedValue.value);
        }
    };

    const handleSelectChange = (selectedValue: IOptionType) => {
        setSelectedItem(selectedValue);
        if (selectedValue) {
            setItemMP(selectedValue.value);
        }
    };

    const handleSelectManufacturers = (values: MultiValue<IOptionType>) => {
        setSelectedManufacturers(values.map((item) => ({
            value: item.value,
            label: item.label
        })));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoadingSubmit(true);
        recordEvent('StartExtractionOfSpecialTools', {'description': 'Extraction of special tools for uploaded PDF files was started'});

        const filter = selectedManufacturers.length > 0 ? {
            "$and": selectedManufacturers.map((manufacturer) => ({
                "Manufacturer": manufacturer.value
            }))
        } : {};

        const payload: ISpecialToolMatchPayload = {
            topK: topK,
            image_threshold: threshold,
            itemMp: itemMP,
            fileIds: JSON.stringify(fileMap),
            setExtractedImages: setExtractedImages,
            setExtractionFinished: setExtractionFinished,
            t: t,
            recordEvent: recordEvent,
            filter: filter
        };

        try {
            const [{
                specialTools: specialToolsResponse,
                manuals: manualsBasedOnSpecialTools
            }, {
                extractedImages: extractedImages,
                manualsBasedOnImages: manualsBasedOnImages
            }] = await handleSpecialToolMatch(payload)

            if (Array.isArray(specialToolsResponse) && specialToolsResponse.length > 0) {
                setSpecialTools(specialToolsResponse)
            }
            if (manualsBasedOnSpecialTools.length > 0) {
                const matchingManuals: Manual[] = [];
                manualsBasedOnSpecialTools.forEach((item: ManualItem) => {
                    if (item) {
                        const manual: Manual = {
                            manualName: item.manualName,
                            manualId: item.manualID,
                            matchedTools: item.matchedSpecialTools.split(', ').map((tool: string) => tool.trim()),
                            additionalTools: item.additionalSpecialTools.split(', ').map((tool: string) => tool.trim()),
                            missingTools: item.missingSpecialTools.split(', ').map((tool: string) => tool.trim()),
                            itemMpText: item.itemMpText
                        };
                        matchingManuals.push(manual);
                    }
                })
                setManualsBasedOnSpecialTools(matchingManuals)
            } else {
                console.log("No matching manuals found")
            }

            if (manualsBasedOnImages.length > 0) {
                const matchingManuals: ImageManual[] = [];
                manualsBasedOnImages.forEach((item: ImageManualItem) => {
                    if (item) {
                        const manual: ImageManual = {
                            manualName: item.manualName,
                            manualId: item.manualId,
                            itemMpText: item.itemMpText,
                            imageFileNameCount: item.imageFileNameCount,
                            imageFileNamesFound: item.imageFileNamesFound,
                            totalImageCount: item.totalImageCount
                        };
                        matchingManuals.push(manual);
                    }
                })
                setManualsBasedOnImages(matchingManuals);
            } else {
                console.log("No matching manuals found")
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoadingSubmit(false); // Stop spinner
        }
    };

    const handleBack = () => {
        setSpecialTools([]);
        setExtractedImages([])
        setExtractionFinished(false)
    };

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {t('PdfFileUpload.ToolTipText')}
        </Tooltip>
    );

    const renderUploadedFiles = () => (
        <Container className='mt-3  justify-content-center'>
            <Row className="justify-content-center text-center mb-0 p-0">
                <p className='mb-1'>{t("PdfFileUpload.UploadedFiles")}</p>
            </Row>
            <Row className="justify-content-center">
                <div style={{
                    maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', // Add border here
                    borderRadius: '4px',     // Optional: add rounded corners
                    padding: '0.5rem'        // Optional: add padding
                }}>
                    <ListGroup>
                        {uploadedFiles.length > 0 ? (
                            uploadedFiles.map((file: File, index) => (
                                <ListGroup.Item key={index}>
                                    {file.name}
                                </ListGroup.Item>
                            ))
                        ) : (
                            <ListGroup.Item>{t("PdfFileUpload.NoFiles")}</ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </Row>
        </Container>
    );

    const handleFilesChange = (selectedOptions: IOptionType[]) => {
        const selectedFiles = selectedOptions.map(option => files.find(file => file.name === option.value));

        // Remove files that are not in the selected options
        const updatedFiles = files.filter(file => selectedFiles.includes(file));

        setFiles(updatedFiles);
    };

    const customStyles = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    const customComponents = {
        DropdownIndicator: () => null,
    };


    return (
        <Container>
            {(specialTools.length > 0 || extractedImages.length > 0) ? (
                <ManualFinder tools={specialTools} onBack={handleBack}
                              extractType={extractType}
                              setExtractType={setExtractType}
                              threshold={threshold}
                              setThreshold={setThreshold}
                              manufacturers={manufacturers}
                              selectedManufacturers={selectedManufacturers}
                              onSelectManufacturers={handleSelectManufacturers}
                />
            ) : (
                <Row className="justify-content-center">
                    <Card className="mb-3 w-50">
                        <Card.Body>
                            <div>
                                {!uploadComplete &&
                                    <div>
                                        <div {...getRootProps()} className="text-center p-4 border-3"
                                             style={dropzoneStyle}>
                                            <input {...getInputProps()} />
                                            {uploadComplete ? (
                                                <p>{t("PdfFileUpload.UploadSuccess")}</p>
                                            ) : (
                                                <p style={{marginTop: '48px'}}>{t("PdfFileUpload.UploadFile")}</p>
                                            )}

                                            <div className="mt-3">
                                                <Button variant="secondary"
                                                        disabled={uploading || uploadComplete}>{t("Button.selectPdf")}</Button>
                                            </div>
                                        </div>
                                        <CreatableSelect
                                            isClearable
                                            className='card border-0 d-md-block mt-3'
                                            closeMenuOnSelect={false}
                                            options={files.map(file => ({label: file.name, value: file.name}))}
                                            value={files.map(file => ({label: file.name, value: file.name}))}
                                            placeholder={t('PdfFileUpload.SelectedFiles')}
                                            isSearchable
                                            isMulti
                                            onChange={(selectedOptions) => handleFilesChange(selectedOptions as IOptionType[])}
                                            styles={customStyles}
                                            noOptionsMessage={() => null}
                                            components={customComponents}
                                        />
                                        <div className="d-flex justify-content-center mt-3">
                                            <Button variant="secondary" onClick={onClickUpload}
                                                    disabled={uploading || uploadComplete || files.length <= 0}>Upload </Button>
                                        </div>
                                    </div>
                                }
                                {uploading && (
                                    <div className="text-center mt-3">
                                        <ProgressBar now={progress} label={`${progress.toFixed(2)}%`}/>
                                    </div>
                                )}

                            </div>

                            {uploadedFiles.length > 0 && renderUploadedFiles()}
                            {uploadComplete && (
                                <Form onSubmit={handleSubmit} className="mt-4">
                                    <div className="mt-3 text-center">
                                        <div className="info-container">
                                            <p className="info-text mb-1">ItemMp</p>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{show: 250, hide: 400}}
                                                overlay={renderTooltip}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon mb-1"/>
                                            </OverlayTrigger>
                                        </div>
                                        <CreatableSelect
                                            isClearable
                                            className='card border-0 d-md-block'
                                            closeMenuOnSelect={false}
                                            options={options}
                                            value={selectedItem ? [selectedItem] : []}
                                            placeholder={t("PdfFileUpload.ItemMPPlaceholder")}
                                            isSearchable
                                            onChange={(choice) => handleSelectChange(choice as IOptionType)}
                                        />
                                    </div>
                                    <div className='text-center mt-3'>
                                        <div>{t("Sidebar.Info")}</div>
                                        <CreatableSelect
                                            className='card border-0 d-md-block'
                                            options={extractOptions}
                                            value={selectedExtractItem ? [selectedExtractItem] : []}
                                            onChange={(choice) => handleSelectChangeExtractType(choice as IOptionType)}
                                        />
                                    </div>
                                    {extractType === ExtractedItem.ImagesManual ? (
                                        <></>
                                    ) : extractType === ExtractedItem.ImagesAutomatic ? (
                                        <>
                                            <div className="mt-3 text-center">
                                                <div className="info-container">
                                                    <div>{t("Filter.manufacturer")}(s):</div>
                                                </div>
                                                <CreatableSelect
                                                    className='card border-0 d-md-block'
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={manufacturers}
                                                    defaultValue={selectedManufacturers}
                                                    placeholder={t("Filter.manufacturer")}
                                                    isSearchable
                                                    onChange={(items) => handleSelectManufacturers(items)}
                                                />
                                            </div>
                                            <div className='text-center mt-3'>
                                                <p className="mb-1">{t("AutomaticImageSelection.Threshold")}: {threshold}</p>
                                                <Form.Control
                                                    min="0" max="1" step="0.1"
                                                    type="range"
                                                    value={threshold}
                                                    onChange={handleThresholdChange}
                                                    className='dropdown-menu-wide mx-auto'
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div className='text-center mt-3'>
                                            <p className="mb-1">{t("PdfFileUpload.TopKResults")}</p>
                                            <Form.Control
                                                type="number"
                                                value={topK}
                                                onChange={handleInputChange}
                                                className='dropdown-menu-wide mx-auto'
                                            />
                                        </div>
                                    )}

                                    <div className="text-center mt-4">
                                        <Button type="submit" variant="secondary" disabled={loadingSubmit}
                                                style={{width: '200px'}}>
                                            {loadingSubmit ? <> <Spinner animation="border"
                                                                         size="sm"/>{' '}{t("DropZone.loading")}</> : t("Button.extractSpecialTools")}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Card.Body>
                        {uploadComplete && (
                            <>
                                <button
                                    onClick={clearFiles}
                                    style={removeButtonStyle}
                                >
                                    <FontAwesomeIcon icon={faTimes}/>
                                </button>
                            </>
                        )}
                    </Card>
                </Row>
            )}
        </Container>
    );
};


export default PdfFileUpload;
