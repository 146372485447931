import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useResultsState} from '../../store';
import {useTranslation} from 'react-i18next';
import aws_config from '../../modified_aws-export';
import {getOnlyMatchToOEM, handleOemMatch, matchActions} from '../../api/backend';
import OnlyMatchToOEMResults from "../ItemResults/OnlyMatchToOEMResults";
import {Button, Container, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {fetchManufacturers, IOptionType} from "../shared/models/common";
import {MultiValue} from "react-select";
import CreatableSelect from "react-select/creatable";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faFilter} from '@fortawesome/free-solid-svg-icons';
import './OnlyMatchToOEMPage.scss';
import {useRecordEvent} from "aws-rum-react";

const OnlyMatchToOEMPage: React.FC = () => {
    const {getResultCount} = useResultsState()
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [noItems, setNoItems] = useState<number>(10)
    const [displayResults, setDisplayResult] = useState<any>([])
    const [manufacturerOptions, setManufactureOptions] = useState<IOptionType[]>([]);
    const [selectedManufacturerOptions, setSelectedManufacturerOptions] = useState<IOptionType[]>([]);
    const recordEvent = useRecordEvent();

    const handleSelectManufacturers = (values: MultiValue<IOptionType>) => {
        setSelectedManufacturerOptions(values.map((item) => ({
            value: item.value,
            label: item.label
        })));
    };

    const fetchOnlyMatchToOEM = async () => {
        try {
            setIsLoading(true);
            const manufacturers = selectedManufacturerOptions.map(manufacturer => {
                return {"Manufacturer": manufacturer.value}
            })
            const filters = [
                {
                    "namespace": "OEM"
                },
                {
                    "matchedBy": "ImageMatcherTool"
                },
                ...manufacturers
            ];
            return await getOnlyMatchToOEM(aws_config.aws_user_files_s3_bucket, Number(noItems), filters);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const noItemsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setNoItems(parseFloat(value));
    };

    const handleApplyFilter = async () => {
        const result = await fetchOnlyMatchToOEM();
        setDisplayResult(result)
    }

    const handleOEMUnMatch = async (metadata: any) => {
        if (window.confirm(t("MatchFunctionality.UnmatchConfirmText"))) {
            setIsLoading(true);
            recordEvent('UnmatchOEM', {'description': 'Unmatch OEM image ' + metadata.FileName});
            await handleOemMatch(matchActions.unmatch, metadata.FileName, t);
            const result = await fetchOnlyMatchToOEM();
            setDisplayResult(result);
        }
    }

    useEffect(() => {
        fetchManufacturers().then((manufacturers) => {
            setManufactureOptions(manufacturers)
        });
        fetchOnlyMatchToOEM().then(result => {
            setDisplayResult(result)
        });
    }, []);

    const popover = (
        <Popover id="filter-popover">
            <Popover.Header as="h3">{t("OnlyMatchToOEM.FilterTitle")}</Popover.Header>
            <Popover.Body>
                <div className="filter-block">
                    <div className="filter-item">
                        <span>{t("Filter.manufacturer")}(s):</span>
                        <div>
                            <CreatableSelect
                                className='card border-0 d-md-block'
                                closeMenuOnSelect={false}
                                isMulti
                                options={manufacturerOptions}
                                defaultValue={selectedManufacturerOptions}
                                placeholder={t("Filter.manufacturer")}
                                isSearchable
                                onChange={(items) => handleSelectManufacturers(items)}
                            />
                        </div>
                    </div>
                    <div className="filter-item">
                        <span>{t("Config.top_k")}: {noItems}</span>
                        <input type="range" className="form-range" min="1" max="1000" step="1" value={noItems}
                               onChange={noItemsChange}>
                        </input>
                    </div>
                    <Button variant="success" size="sm" onClick={handleApplyFilter}>
                        {t("Filter.Apply")}
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    );

    const displayingResult = () => {
        return displayResults.length > 0 ?
            displayResults.map((elem: any) => {
                return (
                    <div className="col justify-content-center d-flex" key={elem.id}>
                        <OnlyMatchToOEMResults metadata={elem.metadata}
                                               elements={elem.elements}
                                               onUnmatchOEMClick={handleOEMUnMatch}/>
                    </div>
                )
            }) :
            <div className="alert alert-warning justify-content-center d-flex" role="alert"
                 style={{width: "80%", margin: "auto"}}>
                {t("Filter.resultsNotFound")}
            </div>
    }

    const displayingSpinner = () => {
        return <Container className="w-80 d-flex justify-content-center align-items-start"
                          style={{paddingTop: "20vh"}}>
            <Spinner animation="border" role="status" className="me-3">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span>{t("OnlyMatchToOEM.Loading")}</span>
        </Container>
    }

    return (
        <div className="only-oem-match-container">
            <div className="result-header">
                <Button variant="secondary"
                        onClick={() => (getResultCount() === 1) ? navigate('/') : navigate('/image-matcher/results')}>
                    <FontAwesomeIcon
                        icon={faAngleLeft}
                        className="ms-2"
                    />
                    <span style={{marginLeft: "8px"}}>{t("OnlyMatchToOEM.Back")}</span>
                </Button>
                <div>
                    <Container className="filter-container">
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                            <Button variant="primary">
                                {t("OnlyMatchToOEM.FilterTitle")}
                                <FontAwesomeIcon
                                    icon={faFilter}
                                    className="ms-2"
                                />
                            </Button>
                        </OverlayTrigger>
                    </Container>
                </div>
            </div>
            <div className="row result-wrapper">
                {isLoading ? displayingSpinner() : displayingResult()}
            </div>
        </div>
    );
}

export default OnlyMatchToOEMPage