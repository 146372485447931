import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next';
import aws_config from '../../modified_aws-export';
import { useConfigState, useResultsState } from '../../store';
import { Link, Navigate } from 'react-router-dom';
import { DropFile } from './DropZone';
import { uploadImage, pineconeQuery } from '../../api/backend'
import { useRecordEvent } from 'aws-rum-react';
import {setLocalItem} from '../../store/slices/pdfuploadSlice'
import { Buffer } from 'buffer'
interface ImageCanvasProps {
    dropfile: DropFile
}



const ImageCanvas: React.FC<ImageCanvasProps> = ({ dropfile }) => {

    const canvR = useRef<HTMLCanvasElement>(null)
    const { t } = useTranslation();
    const [scale, setScale] = useState<number>(0);
    const { top_k } = useConfigState()
    const [processed, setProcessed] = useState<boolean>(false);
    const { results, setResults, getResultCount } = useResultsState()
    //const [result, setresult] = useState(dropfile.result)
    const recordEvent = useRecordEvent();

    const [imageElement, setimageElement] = useState<HTMLImageElement>()

    const maxHeight = 250;
    const maxWidth = 400;


    useEffect(() => {
        const image = new window.Image();
        if (dropfile.fileobject) {
            image.src = URL.createObjectURL(dropfile.fileobject);
            image.onload = () => {
                var yScale = (maxHeight / image.naturalHeight)
                var xScale = (maxWidth / image.naturalWidth)
                if (yScale < xScale) {
                    setScale(yScale)
                } else {
                    setScale(xScale)
                }
                setimageElement(image)
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (imageElement) {
            if (canvR != null && canvR.current != null) {
                const canvas = canvR.current
                canvas.height = imageElement.naturalHeight * scale
                canvas.width = imageElement.naturalWidth * scale
                var ctx = canvas.getContext('2d');
                ctx?.drawImage(
                    imageElement,
                    0,
                    0,
                    imageElement.naturalWidth,
                    imageElement.naturalHeight,
                    0,
                    0,
                    (imageElement.naturalWidth * scale),
                    (imageElement.naturalHeight * scale),
                )
                canvas.toBlob(async (blob) => {
                    if (!dropfile.id) {
                        // var id = uuidv4()
                        
                        if (blob) {
                            const arrayBuffer = await blob.arrayBuffer();
                            const buffer = Buffer.from(arrayBuffer);
                            const hash = await window.crypto.subtle.digest("SHA-256", buffer );
                            const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
                            const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
                            var filename = "image_similarity_search/" + hashHex + ".png"
                            // Create a File object from the Blob
                            const new_uploadfile = new File([blob], filename, { type: 'image/png' });
                            dropfile.fileobject = new_uploadfile;
                            let response = await uploadImage(new_uploadfile)
                            if (response) {
                                dropfile.id = hashHex
                                await pineconeQuery(dropfile, aws_config.aws_user_files_s3_bucket, dropfile.fileobject?.name as string, Number(top_k), setResults, recordEvent, JSON.stringify({ "namespace": {"$in":["OEM","Single"]}}))
                                setProcessed(true)
                            }
                        }

                    } else {
                        setProcessed(true)
                    }
                }, 'image/png')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageElement])


   
    

    return (
        <div className='d-flex justify-content-center'>
            <canvas ref={canvR} height={maxHeight} width={maxWidth} style={{ margin: "5px" }}></canvas>
            {
                results && dropfile.id && processed ?
                    <>
                        {getResultCount() === 1 ? <Navigate to={dropfile.id} />
                            :
                            <>  
                                <Link to={dropfile.id} state={{ fromImageCanvas: true }}  style={{ alignSelf: 'center' }}> <button className="btn btn-secondary  mt-2" type="button" >
                                    {t('DropZone.showResults')}
                                </button></Link>
                            </>
                        }
                    </>
                    :
                    <>
                        <button className="btn btn-secondary  mt-2" type="button" disabled style={{ alignSelf: 'center' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span> {t('DropZone.loading')}</span>
                        </button>
                    </>
            }
        </div>
    )
}

export default ImageCanvas