/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getResult = /* GraphQL */ `query GetResult(
  $id: String!
  $bucket: String!
  $key: String!
  $top_k: Int
  $filter: AWSJSON
  $model: String
  $task_name: String
) {
  getResult(
    id: $id
    bucket: $bucket
    key: $key
    top_k: $top_k
    filter: $filter
    model: $model
    task_name: $task_name
  )
}
` as GeneratedQuery<APITypes.GetResultQueryVariables, APITypes.GetResultQuery>;
export const handleOemMatch = /* GraphQL */ `query HandleOemMatch(
  $action: MatchAction!
  $ta_file_name: String!
  $bucket: String!
  $backend_bucket: String!
  $oem_hash_file_name: String
  $metadata: String
  $aim_model_name: String
  $aim_task_name: String
  $vit_model_name: String
  $vit_task_name: String
) {
  handleOemMatch(
    action: $action
    ta_file_name: $ta_file_name
    bucket: $bucket
    backend_bucket: $backend_bucket
    oem_hash_file_name: $oem_hash_file_name
    metadata: $metadata
    aim_model_name: $aim_model_name
    aim_task_name: $aim_task_name
    vit_model_name: $vit_model_name
    vit_task_name: $vit_task_name
  )
}
` as GeneratedQuery<
  APITypes.HandleOemMatchQueryVariables,
  APITypes.HandleOemMatchQuery
>;
export const extractSpecialTools = /* GraphQL */ `query ExtractSpecialTools(
  $pdfIds: String!
  $topK: Int!
  $itemMp: String
  $subscriptionId: String!
  $image_threshold: Float
  $filter: AWSJSON
) {
  extractSpecialTools(
    pdfIds: $pdfIds
    topK: $topK
    itemMp: $itemMp
    subscriptionId: $subscriptionId
    image_threshold: $image_threshold
    filter: $filter
  )
}
` as GeneratedQuery<
  APITypes.ExtractSpecialToolsQueryVariables,
  APITypes.ExtractSpecialToolsQuery
>;
export const refineMatch = /* GraphQL */ `query RefineMatch($specialToolIds: String!, $topK: Int!) {
  refineMatch(specialToolIds: $specialToolIds, topK: $topK)
}
` as GeneratedQuery<
  APITypes.RefineMatchQueryVariables,
  APITypes.RefineMatchQuery
>;
export const getManualHtml = /* GraphQL */ `query GetManualHtml($input: GetManualHtmlInput) {
  getManualHtml(input: $input)
}
` as GeneratedQuery<
  APITypes.GetManualHtmlQueryVariables,
  APITypes.GetManualHtmlQuery
>;
export const listManualsByImages = /* GraphQL */ `query ListManualsByImages($fileNames: [String!]) {
  listManualsByImages(fileNames: $fileNames)
}
` as GeneratedQuery<
  APITypes.ListManualsByImagesQueryVariables,
  APITypes.ListManualsByImagesQuery
>;
export const getResultsForExtractedImagesFromPdf = /* GraphQL */ `query GetResultsForExtractedImagesFromPdf(
  $id: String!
  $bucket: String!
  $key: String!
  $top_k: Int!
  $threshold: Float!
  $filter: AWSJSON
) {
  getResultsForExtractedImagesFromPdf(
    id: $id
    bucket: $bucket
    key: $key
    top_k: $top_k
    threshold: $threshold
    filter: $filter
  )
}
` as GeneratedQuery<
  APITypes.GetResultsForExtractedImagesFromPdfQueryVariables,
  APITypes.GetResultsForExtractedImagesFromPdfQuery
>;
