import { useStore } from "./useStore";

export const useExtractedImagesState = () => {

    const {
        selectedImages,
        addToselectedImages,
        removeFromselectedImages,
        resetSelectedImages,
        setDisplayImageManuals,
        displayImageManuals,

    } = useStore();

    return {
        selectedImages,
        addToselectedImages,
        removeFromselectedImages,
        resetSelectedImages,
        setDisplayImageManuals,
        displayImageManuals,
    }
}