import {useTranslation} from "react-i18next";
import {copyManualIdToClipboard, handleManualClick, ImageManual} from "../../utils/manualUtils";
import React, {useEffect, useState} from "react";
import {Badge, Button, Card, Container, ListGroup, Spinner} from "react-bootstrap";
import './ManualFinder.scss';
import {toast} from "react-toastify";
import {useRecordEvent} from "aws-rum-react";

interface MatchingManualsImageProps {
    manuals: ImageManual[];
    isLoading: boolean;
}

const MatchingManualsImages: React.FC<MatchingManualsImageProps> = ({manuals, isLoading}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isManualFeedbackProvided, setIsManualFeedbackProvided] = useState(false);
    const recordEvent = useRecordEvent();

    useEffect(() => {
        setIsManualFeedbackProvided(false)
    }, [isLoading]);

    const onHandleManualClick = async (manual: ImageManual) => {
        handleManualClick(manual.manualId, setLoading, t);
    }


    const handleClickFeedbackButtons = (
        isFound: boolean
    ) => {
        recordEvent("AutoManualSearchFeedbackButtonClicked", {
            status: isFound ? "Manual Found" : "Manual not found",
            foundManuals: manuals,
        });
        setIsManualFeedbackProvided(true);
        toast(t("ExtractedImages.FeedbackSubmitted"), {type: "success"});
    };

    if (isLoading) {
        return (
            <Container className="w-80 d-flex justify-content-center align-items-start"
                       style={{paddingTop: "20vh"}}
            >
                <Spinner animation="border" role="status" className="me-3">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>{t("AutomaticImageSelection.RefineMatch")}</span>
            </Container>
        );
    }

    if (manuals.length <= 0) {
        return (
            <Container className="w-80 d-flex justify-content-center align-items-start" style={{paddingTop: '20vh'}}>
                <span>{t("AutomaticImageSelection.NoManualsFound")}</span>
            </Container>
        );
    }

    return (
        <div className="d-flex justify-content-center manual-details-listgroup">
            <ListGroup className="w-75">
                <ListGroup.Item key={-1} className="feedback-session">
                    <Button variant="success"
                            onClick={() => handleClickFeedbackButtons(true)}
                            disabled={isManualFeedbackProvided}>
                        {t("ExtractedImages.ManualFoundButton")}
                    </Button>
                    <Button variant="danger"
                            onClick={() => handleClickFeedbackButtons(false)}
                            disabled={isManualFeedbackProvided}>
                        {t("ExtractedImages.ManualNotFoundButton")}
                    </Button>
                </ListGroup.Item>
                {manuals.map((manual, index) => (
                    <ListGroup.Item key={index} className="manual-item">
                        <div className="row ">
                            <div className="col-2">
                            </div>
                            <div className="col-8 text-center mb-0">
                                <h5>{manual.itemMpText}</h5>
                            </div>
                            <div className="col-2 text-end mb-0">
                                <p className='manual-id'
                                   onDoubleClick={() => copyManualIdToClipboard(manual.manualId, t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId))}>{`ID: ${manual.manualId}`}</p>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-3  text-center">
                            </div>
                            <div className="col-6  text-center">
                                <a href="#" onClick={() => onHandleManualClick(manual)}>
                                    <p className="manual-name">{manual.manualName}</p>
                                </a>
                            </div>
                            <div className="col-3  text-center">
                            </div>
                        </div>
                        <Card.Body>
                            <Card.Title className="fs-6">{t("AutomaticImageSelection.CountFoundImages")}</Card.Title>
                            <div className="tool-labels">
                                {manual.imageFileNameCount} {t("AutomaticImageSelection.outof")} {manual.totalImageCount}
                            </div>
                            <hr/>
                            <Card.Title className="fs-6">{t("AutomaticImageSelection.FoundImages")}</Card.Title>
                            <div className="tool-labels">
                                {manual.imageFileNamesFound.map((filenmae, idx) => (
                                    <Badge key={idx} className="filenamle-label">
                                        {filenmae}
                                    </Badge>
                                ))}
                            </div>
                        </Card.Body>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    )
}

export default MatchingManualsImages;