import React, {useState} from "react";
import Sidebar from "./Sidebar";
import MatchingManualsSpecialTools from "./MatchingManualSpecialTools"; // Importing Manual and MatchingManual components
import {handleGetManualsByImages, handleRefineImagesAutomatic, handleRefineMatches,} from "../../api/backend"; // Assuming handleRefineMatches returns some response
import {useTranslation} from "react-i18next";
import ExtractedImages from "../PdfImageExtractor/ExtractedImages";
import {useExtractedImagesState, usePdfUploadState} from "../../store/hooks";
import {ImageManual, ImageManualItem, Manual, ManualItem,} from "../../utils/manualUtils";
import MatchingManualsImages from "./MatchingManualImages";
import {toast} from "react-toastify";
import {ExtractedItem} from "../shared/models/constants";
import {IOptionType} from "../shared/models/common";
import {MultiValue} from "react-select";

interface SpecialToolProps {
    tools: string[];
    onBack: () => void;
    extractType: string;
    setExtractType: (value: string) => void;
    threshold: number;
    setThreshold: (value: number) => void;
    manufacturers: IOptionType[];
    selectedManufacturers: IOptionType[];
    onSelectManufacturers: (values: MultiValue<IOptionType>) => void;
}

const ManualFinder: React.FC<SpecialToolProps> = ({
                                                      tools,
                                                      onBack,
                                                      extractType,
                                                      setExtractType,
                                                      threshold,
                                                      setThreshold,
                                                      manufacturers,
                                                      selectedManufacturers,
                                                      onSelectManufacturers
                                                  }) => {
    const {
        manualsBasedOnSpecialTools,
        setManualsBasedOnSpecialTools,
        manualsBasedOnImages,
        extractedImages,
        setManualsBasedOnImages
    } = usePdfUploadState()
    const [imageManuals, setimageManuals] = useState<ImageManual[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const {setDisplayImageManuals} = useExtractedImagesState();
    const {t} = useTranslation();
    const onFilterMatches = async (filteredTools: string[], topK: number) => {
        setIsLoading(true);
        const stringifiedTools = JSON.stringify(filteredTools);
        const manualResponse = await handleRefineMatches(stringifiedTools, topK);
        if (manualResponse.length > 0) {
            const matchingManuals: Manual[] = [];
            manualResponse.forEach((item: ManualItem) => {
                if (item) {
                    // const item = manualResponse[id];
                    const manual: Manual = {
                        manualName: item.manualName,
                        manualId: item.manualID,
                        matchedTools: item.matchedSpecialTools
                            .split(", ")
                            .map((tool: string) => tool.trim()),
                        additionalTools: item.additionalSpecialTools
                            .split(", ")
                            .map((tool: string) => tool.trim()),
                        missingTools: item.missingSpecialTools
                            .split(", ")
                            .map((tool: string) => tool.trim()),
                        itemMpText: item.itemMpText,
                    };
                    matchingManuals.push(manual);
                }
            });
            setManualsBasedOnSpecialTools(matchingManuals);
        }
        setIsLoading(false);
    };

    const onGetManualsByImages = async (fileNames: string[], topK: number) => {
        setIsLoading(true);
        const manualResponse = await handleGetManualsByImages(fileNames, topK);
        if (manualResponse.length > 0) {
            const _imageManuals: ImageManual[] = [];
            manualResponse.forEach((item: ImageManualItem) => {
                if (item) {
                    // const item = manualResponse[id];
                    const manual: ImageManual = {
                        manualName: item.manualName,
                        manualId: item.manualId,
                        itemMpText: item.itemMpText,
                        imageFileNameCount: item.imageFileNameCount,
                        imageFileNamesFound: item.imageFileNamesFound,
                        totalImageCount: item.totalImageCount,
                    };
                    _imageManuals.push(manual);
                }
            });
            setimageManuals(_imageManuals);
            setDisplayImageManuals(true);
        } else {
            toast(t("ExtractedImages.ManualsNotFound"), {type: "warning"});
        }
        setIsLoading(false);
    };

    const onRefineImagesAutomaticThreshold = async () => {
        setIsLoading(true);
        const imageNames = extractedImages.map((obj: any) => Object.keys(obj)).flat();
        const {manualsBasedOnImages} = await handleRefineImagesAutomatic(imageNames, threshold);
        const matchingManuals = manualsBasedOnImages
            .filter((item: ImageManualItem) => item && item)
            .map((item: ImageManualItem) => ({
                manualName: item.manualName,
                manualId: item.manualId,
                itemMpText: item.itemMpText,
                imageFileNameCount: item.imageFileNameCount,
                imageFileNamesFound: item.imageFileNamesFound,
                totalImageCount: item.totalImageCount
            } as ImageManual));
        setManualsBasedOnImages(matchingManuals);
        setIsLoading(false);
    };

    const handleDownload = () => {
        const csvContent = convertToCSV(manualsBasedOnSpecialTools);
        const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "matchingManuals.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data: Manual[]) => {
        const headers = [
            "ManualId",
            "ManualName",
            "ItemMpText",
            "MatchedTools",
            "AdditionalTools",
            "MissingTools",
        ];
        const rows = data.map((manual) => [
            manual.manualId,
            manual.manualName.replace(";", ","), // replace all ; with ,
            manual.itemMpText,
            manual.matchedTools.join(", "),
            manual.additionalTools.join(", "),
            manual.missingTools.join(", "),
        ]);

        const csvRows = [
            headers.join(";"), // header row first
            ...rows.map((row) => row.join(";")), // data rows
        ];

        return csvRows.join("\n");
    };

    return (
        <div>
            <Sidebar
                tools={tools}
                onBack={onBack}
                onFilterMatches={onFilterMatches}
                handleDownload={handleDownload}
                isLoading={isLoading}
                extractType={extractType}
                onExtractTypeChange={setExtractType}
                onGetManualsByImages={onGetManualsByImages}
                onRefineThreshold={onRefineImagesAutomaticThreshold}
                threshold={threshold}
                setThreshold={setThreshold}
                manufacturers={manufacturers}
                selectedManufacturers={selectedManufacturers}
                onSelectManufacturers={onSelectManufacturers}
            />

            <div className="fixed-height">
                {extractType === ExtractedItem.ImagesManual ? (
                    <ExtractedImages imageManuals={imageManuals}/>
                ) : extractType === ExtractedItem.ImagesAutomatic ? (
                    <MatchingManualsImages manuals={manualsBasedOnImages} isLoading={isLoading}/>
                ) : (
                    <MatchingManualsSpecialTools manuals={manualsBasedOnSpecialTools}/>
                )}
            </div>
        </div>
    );
};

export default ManualFinder;
