import React, {useEffect, useState} from 'react';
import {Button, Form, ListGroup, Offcanvas, Spinner} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {useExtractedImagesState} from '../../store';
import './ManualFinder.scss';
import {ExtractedItem} from "../shared/models/constants";
import CreatableSelect from "react-select/creatable";
import {IOptionType} from "../shared/models/common";
import {MultiValue} from "react-select";

interface SidebarProps {
    tools: string[];
    onBack: () => void;
    onFilterMatches: (selectedTools: string[], topK: number) => void;
    handleDownload: () => void;
    isLoading: boolean;
    extractType: string;
    onExtractTypeChange: (value: string) => void;
    onGetManualsByImages: (fileNames: string[], topK: number) => void
    onRefineThreshold: () => void,
    threshold: number;
    setThreshold: (value: number) => void;
    manufacturers: IOptionType[];
    selectedManufacturers: IOptionType[];
    onSelectManufacturers: (values: MultiValue<IOptionType>) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
                                             tools,
                                             onBack,
                                             onFilterMatches,
                                             handleDownload,
                                             isLoading,
                                             extractType,
                                             onExtractTypeChange,
                                             onGetManualsByImages,
                                             onRefineThreshold,
                                             threshold,
                                             setThreshold,
                                             manufacturers,
                                             selectedManufacturers,
                                             onSelectManufacturers
                                         }) => {
    const [checkedTools, setCheckedTools] = useState(tools.map(() => true));
    const [topK, settopK] = useState<number>(5);
    const {t} = useTranslation();
    const {selectedImages} = useExtractedImagesState();

    useEffect(() => {
        setCheckedTools(tools.map(() => true));
    }, [tools])

    const handleCheckboxChange = (index: number) => {
        const newCheckedTools = [...checkedTools];
        newCheckedTools[index] = !newCheckedTools[index];
        setCheckedTools(newCheckedTools);
    };

    const handleAction = (event: any) => {
        event.preventDefault();
        const selectedTools = tools.filter((tool, index) => checkedTools[index]);
        onFilterMatches(selectedTools, topK);
    };

    const handleRefineThreshold = (event: any) => {
        event.preventDefault();
        onRefineThreshold();
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        settopK(parseInt(value));
    };

    const handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setThreshold(parseFloat(value));
    };

    const onClickDownload = () => {
        handleDownload()
    };

    const getManuals = () => {
        onGetManualsByImages(selectedImages, 10)
    }

    return (
        <Offcanvas show={true} scroll={true} backdrop={false} className="custom-offcanvas">
            <Offcanvas.Header className="d-flex justify-content-center align-items-center">
                <Offcanvas.Title></Offcanvas.Title>
                <div className="text-center mt-3">
                    <Button variant="secondary" onClick={onBack}
                            disabled={isLoading}>{t("SpecialTool.BackButton")}</Button>
                </div>

            </Offcanvas.Header>
            <Offcanvas.Body>
                <div>{t("Sidebar.Info")}</div>
                <div className="mt-3">
                    <Form.Check
                        type="radio"
                        id="showImagesManualSelection"
                        label={t("Sidebar.ImagesManualSelection")}
                        checked={extractType === ExtractedItem.ImagesManual}
                        onChange={() => onExtractTypeChange(ExtractedItem.ImagesManual)}
                        //disabled={isLoading}
                    />
                    <Form.Check
                        type="radio"
                        id="showImagesAutomaticSelection"
                        label={t("Sidebar.ImagesAutomaticSelection")}
                        checked={extractType === ExtractedItem.ImagesAutomatic}
                        onChange={() => onExtractTypeChange(ExtractedItem.ImagesAutomatic)}
                        //disabled={isLoading}
                    />
                    <Form.Check
                        type="radio"
                        id="showTools"
                        label={t("Sidebar.SpecialTools")}
                        checked={extractType === ExtractedItem.SpecialTools}
                        onChange={() => onExtractTypeChange(ExtractedItem.SpecialTools)}
                        //disabled={isLoading}
                    />
                    <hr/>
                </div>
                {extractType === ExtractedItem.ImagesManual ? (
                    <div className="mt-4">
                        <h5>{t("Sidebar.SelectedImages")}</h5>
                        <ListGroup className="mb-3 scrollable-list">
                            {selectedImages.map((filename, index) => (
                                <ListGroup.Item key={index}>{filename}</ListGroup.Item>
                            ))}
                        </ListGroup>
                        <div className="d-flex justify-content-center">
                            <Button onClick={getManuals} variant="secondary" type="submit"
                                    disabled={isLoading || selectedImages.length <= 0}>
                                {isLoading ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status"
                                                 aria-hidden="true"/>
                                        {' '}{t("DropZone.loading")}
                                    </>
                                ) : (
                                    t("SpecialTool.GetManualsButton")
                                )}
                            </Button>
                        </div>
                    </div>
                ) : extractType === ExtractedItem.ImagesAutomatic ? (
                    <>
                        <Form onSubmit={handleRefineThreshold}>
                            <Form.Group className="mt-3">
                                <Form.Label>{t("AutomaticImageSelection.Threshold")} {threshold}</Form.Label>
                                <Form.Control
                                    min="0" max="1" step="0.1"
                                    type="range"
                                    value={threshold}
                                    onChange={handleThresholdChange}
                                    className='dropdown-menu-wide mx-auto'
                                />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>{t("Filter.manufacturer")}(s):</Form.Label>
                                <CreatableSelect
                                    className='card border-0 d-md-block'
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={manufacturers}
                                    defaultValue={selectedManufacturers}
                                    placeholder={t("Filter.manufacturer")}
                                    isSearchable
                                    onChange={(items) => onSelectManufacturers(items)}
                                />
                            </Form.Group>
                            <div className="text-center mt-3">
                                <Button variant="secondary" type="submit" disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status"
                                                     aria-hidden="true"/>
                                            {' '}{t("DropZone.loading")}
                                        </>
                                    ) : (
                                        t("SpecialTool.FilterButton")
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </>
                ) : (
                    <>
                        <div>{t("SpecialTool.SpecialTools")}</div>
                        {tools.length > 0 ? (
                            <>
                                <Form onSubmit={handleAction}>
                                    {tools.map((tool, index) => (
                                        <Form.Check
                                            aria-label="option 1"
                                            key={index}
                                            type="checkbox"
                                            id={`tool-${index}`}
                                            label={tool}
                                            checked={checkedTools[index]}
                                            onChange={() => handleCheckboxChange(index)}
                                            disabled={isLoading}
                                        />
                                    ))}
                                    <Form.Group className="mt-3">
                                        <Form.Label>{t("PdfFileUpload.TopKResults")}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={topK}
                                            onChange={handleNumberChange}
                                            disabled={isLoading}
                                        />
                                    </Form.Group>
                                    <div className="text-center mt-3">
                                        <Button variant="secondary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status"
                                                             aria-hidden="true"/>
                                                    {' '}{t("DropZone.loading")}
                                                </>
                                            ) : (
                                                t("SpecialTool.FilterButton")
                                            )}
                                        </Button>
                                    </div>
                                </Form>

                                <div className="text-center mt-3">
                                    <Button variant="secondary" onClick={onClickDownload} disabled={isLoading}>
                                        {t("SpecialTool.DownloadButton")}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className='mt-2'>{t("SpecialTool.NoToolsFound")}</div>
                        )}
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default Sidebar;