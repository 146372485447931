import { useTranslation } from "react-i18next";
import { useConfigState } from '../../store';
import Form from 'react-bootstrap/Form';
import { useRef } from 'react';

const ShowDeepSearchHint = () => {
    const showDeepSearchHint_ref = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    const { setShowDeepSearchHint, showDeepSearchHint } = useConfigState()

    const change = (elem: any) => {
        if (showDeepSearchHint_ref.current?.value) {
            setShowDeepSearchHint(Boolean(showDeepSearchHint_ref.current?.checked))
        }
    }

    return (
        <>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch-showDeepSearchHint"
                    label={t('Config.ShowDeepSearchHint')}
                    onChange={(elem) => change(elem)}
                    ref={showDeepSearchHint_ref}
                    checked={showDeepSearchHint}
                />
            </Form>
        </>
    )
}

export default ShowDeepSearchHint