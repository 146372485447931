
import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import {Container,ListGroup, Card, Badge,  Spinner  } from 'react-bootstrap';
import './ManualFinder.scss';
import { toast } from 'react-toastify';
import { Manual, copyManualIdToClipboard, handleManualClick} from '../../utils/manualUtils'


interface MatchingManualsProps {
    manuals: Manual[];
  }

const MatchingManualsSpecialTools:React.FC<MatchingManualsProps> = ({manuals}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);  

  
    const onHandleManualClick = async (manual:Manual) => {
      handleManualClick(manual.manualId, setLoading, t);

    }


    if (manuals.length<=0) {
      return (
          <Container className="w-80 d-flex justify-content-center align-items-start" style={{  paddingTop: '20vh' }}>
          <span>{t("SpecialTool.NoMatchingManuals")}</span>
      </Container>
      );
  }

   return (
    <div className="d-flex justify-content-center manual-details-listgroup mt-4">
        {loading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}


    <ListGroup className="w-75">
        {manuals.map((manual, index) => (
            <ListGroup.Item key={index} className="manual-item">
            <div className="row ">
            <div className="col-2">
              </div>
              <div className="col-8 text-center mb-0">
                <h5>{manual.itemMpText}</h5>
              </div>
              <div className="col-2 text-end mb-0">
                <p className='manual-id' onDoubleClick={() =>copyManualIdToClipboard(manual.manualId, t("SpecialTool.CopyIdClipboardMessage").replace("{}", manual.manualId)) }>{`ID: ${manual.manualId}`}</p>
              </div>
            </div>
            <div className="row align-items-center">
            <div className="col-3  text-center">
              </div>
              <div className="col-6  text-center">
              <a href="#" onClick={() => onHandleManualClick(manual)}>
                <p className="manual-name">{manual.manualName}</p>
                </a>
              </div> 
              <div className="col-3  text-center">
              </div>         
            </div>
                <Card.Body>
                    <Card.Title className="fs-6">{t("SpecialTool.MatchedTools")}</Card.Title>
                    <div className="tool-labels">
                        {manual.matchedTools.map((tool, idx) => (
                            <Badge key={idx} className="matched-tool-label">
                                {tool}
                            </Badge>
                        ))}
                    </div>
                    <hr />
                    <Card.Title className="fs-6">{t("SpecialTool.AdditionalTools")}</Card.Title>
                    <div className="tool-labels">
                        {manual.additionalTools.map((tool, idx) => (
                            <Badge key={idx} className="additional-tool-label">
                                {tool}
                            </Badge>
                        ))}
                    </div>
                    <hr />
                    <Card.Title className="fs-6">{t("SpecialTool.MissingTools")}</Card.Title>
                    <div className="tool-labels">
                        {manual.missingTools.map((tool, idx) => (
                            <Badge key={idx} className="notfound-tool-label">
                                {tool}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </ListGroup.Item>
        ))}
    </ListGroup>
</div>
);
};

export default MatchingManualsSpecialTools