import { useStore } from "./useStore";

export const usePdfUploadState = () => {

    const {
        uploadComplete,
        uploadedFiles,
        fileMap,
        setUploadComplete,
        setFileMap,
        setUploadedFiles,
        clearFileMap,
        clearUploadedFiles,
        extractionFinished,
        setExtractionFinished,
        extractedImages,
        setExtractedImages,
        specialTools,
        setSpecialTools,
        manualsBasedOnSpecialTools,
        setManualsBasedOnSpecialTools,
        manualsBasedOnImages,
        setManualsBasedOnImages,
    } = useStore();

    return {
        uploadComplete,
        uploadedFiles,
        fileMap,
        setUploadComplete,
        setFileMap,
        setUploadedFiles,
        clearFileMap,
        clearUploadedFiles,
        extractionFinished,
        setExtractionFinished,
        extractedImages,
        setExtractedImages,
        specialTools,
        setSpecialTools,
        manualsBasedOnSpecialTools,
        setManualsBasedOnSpecialTools,
        manualsBasedOnImages,
        setManualsBasedOnImages
    }
}